export default function selectText(allText: any, language: "nl" | "fr" | "en") {
  const selectedText = {} as {
    [P in keyof typeof allText]: string;
  };
  for (const k in allText) {
    const v = allText[k][language];
    selectedText[k] = v;
  }
  return selectedText;
}
